<script setup lang="ts">
import {computed} from 'vue'

type ButtonVariantWithoutCustom = 'primary' | 'secondary' | 'light' | 'success' | 'warning' | 'warning-text'
export type ButtonVariant = ButtonVariantWithoutCustom | 'custom'

interface Props {
  customVariant?: string
  id?: string
  label?: string
  variant?: ButtonVariant
  isLoading?: boolean
  isDisabled?: boolean
}

const VARIANT_STYLES: Record<ButtonVariantWithoutCustom, string> = {
  primary: 'bg-branddark hover:bg-branddarkdarker',
  secondary: 'bg-brandmain hover:bg-rbc-tertiary',
  light: 'bg-brandlight hover:bg-brandlightdarker',
  success: 'bg-olive hover:bg-darkolive',
  warning: 'bg-red hover:bg-darkred',
  'warning-text': '!text-red bg-lightgrey hover:bg-darkgrey',
}

const props = withDefaults(defineProps<Props>(), {
  customVariant: '',
  variant: 'primary',
})

const variantStyles = computed(() => (props.variant === 'custom' ? props.customVariant : VARIANT_STYLES[props.variant]))

const classes = computed(() => ({
  'w-fit py-2 px-5 uppercase text-white tracking-widest transition-all relative': true, // default style
  'opacity-25': props.isDisabled && !props.isLoading, // disabled style
  'opacity-50': props.isDisabled && props.isLoading, // disabled && loading style
  [variantStyles.value]: true,
}))
</script>

<template>
  <button type="button" :class="classes" :disabled="isDisabled || isLoading" :id="id">
    <span v-if="isLoading" class="absolute w-full left-0">
      <i class="fa-solid fa-circle-notch animate-spin leading-none tracking-normal"></i>
    </span>
    <span :style="{visibility: isLoading ? 'hidden' : undefined}">
      <slot />
      <span>{{ label }}</span>
    </span>
  </button>
</template>
