import type {Maybe} from '../types'

const CURRENCY_SYMBOLS: {[key: string]: string} = {
  USD: '$',
  CHF: 'CHF',
  GBP: '£',
  EUR: '€',
}

const defaultCurrencySymbol = CURRENCY_SYMBOLS['EUR']

export function encodeBase64(data: any) {
  return btoa(encodeURIComponent(JSON.stringify(data)))
}

export function decodeBase64(data: string) {
  return JSON.parse(decodeURIComponent(atob(data)))
}

export function deepCopyData(object: any) {
  return JSON.parse(JSON.stringify(object))
}

export function isNotNullOrUndefined<T>(value: Maybe<T>): value is T {
  return !isNullOrUndefined(value)
}

export function isNullOrUndefined(value: any): value is null | undefined {
  return value === null || value === undefined
}

export function last<T>(values: T[]): Maybe<T> {
  return values.slice(-1)[0]
}

export function safeJsonParse(s: string) {
  try {
    return JSON.parse(s)
  } catch {
    return s
  }
}

export function extractCurrencySymbol(s: Maybe<string>): string {
  return CURRENCY_SYMBOLS[s ?? 'EUR'] ?? defaultCurrencySymbol
}
