import {useCookies} from 'vue3-cookies'

class ExpiryCookie {
  get(): number {
    const cookies = useCookies().cookies
    const cookieString = cookies.get('rbtexp')
    const expTime = parseInt(cookieString)
    return cookieString && isFinite(expTime) ? expTime : 0
  }

  isAuthenticated() {
    return !this.isExpired()
  }

  isExpired() {
    const currentTime = new Date().getTime()
    return this.get() <= currentTime
  }
}

export default new ExpiryCookie()
