import {createI18n} from 'vue-i18n'

import en from './locales/en.json'
import de from './locales/de.json'
import it from './locales/it.json'

const navigatorLanguage = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

export const browserLanguage = navigatorLanguage.split('-')[0] || 'en'
export const browserLocale = navigatorLanguage

const i18n = createI18n({
  legacy: false,
  locale: browserLanguage,
  fallbackLocale: 'en',
  globalInjection: true,
  messages: {
    en: en,
    de: de,
    it: it,
  },
})

export default i18n
