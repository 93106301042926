import axios, {type AxiosResponse, type InternalAxiosRequestConfig} from 'axios'
import * as Sentry from '@sentry/vue'
import {v4 as uuidv4} from 'uuid'
import {useLoadingStore} from './stores/loading'
import ExpiryCookie from './utils/ExpiryCookie'

const EXPIRED_ERROR = 'rbt.expired'
const defaultApi = axios.create({})

function onRequestFulfilled(config: InternalAxiosRequestConfig<any>) {
  if (ExpiryCookie.isExpired()) {
    return Promise.reject(new Error(EXPIRED_ERROR))
  }

  const loadingStore = useLoadingStore()
  if (!config.customData) {
    const id = uuidv4().toString()
    config.customData = {id}
    loadingStore.__addRequestId(id)
  } else if (config.customData && !config.customData.isExcludedFromLoadingBar) {
    config.customData.id = uuidv4().toString()
    loadingStore.__addRequestId(config.customData.id)
  }
  return config
}

function onResponseFulfilled(response: AxiosResponse) {
  if (response.config.customData?.id && !response.config.customData.isExcludedFromLoadingBar) {
    const loadingStore = useLoadingStore()
    loadingStore.__removeRequestId(response.config.customData.id)
  }
  return response
}

function onResponseRejected(error: any) {
  if (error.message === EXPIRED_ERROR || (error.config?.redirectOn401 && error.response?.status === 401)) {
    window.location.replace('/login')
    return new Promise((_, reject) => {
      setTimeout(() => {
        reject(error)
      }, 2000)
    })
  } else {
    if (error.config?.customData !== undefined) {
      if (!error.config.customData.isExcludedFromLoadingBar) {
        const loadingStore = useLoadingStore()
        loadingStore.__removeRequestId(error.config.customData.id)
      }
      Sentry.setContext('axios', {url: error.config.url})
    } else {
      Sentry.setContext('axios', {noConfig: true})
    }
    return Promise.reject(error)
  }
}

export function interceptorsSetup() {
  defaultApi.interceptors.request.use(onRequestFulfilled)
  defaultApi.interceptors.response.use(onResponseFulfilled, onResponseRejected)

  axios.interceptors.request.use(onRequestFulfilled)
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected)
}

export {defaultApi}
