import type {AxiosResponse} from 'axios'
import type {Maybe} from '@/types'
import {defaultApi} from '@/axios'
import {isNotNullOrUndefined} from '@/utils/utils'
import ExpiryCookie from '@/utils/ExpiryCookie'

const MIN_PWD_LENGTH = 8
const REFRESH_BEFORE_MS = 5 * 60 * 1000

export interface AuthUser {
  id: number
  passwordResetActive: boolean
  roles: string[]
  username: string
}

export interface Tokens {
  access_token: string
}

export interface NewPassword {
  newPassword: string
  newPasswordConfirmation: string
}

export interface ChangePassword extends NewPassword {
  oldPassword: string
}

export interface ResetPassword extends NewPassword {
  token: string
}

let refreshTimeoutId: number | undefined = undefined

class AuthService {
  #validateNewPassword(data: NewPassword) {
    if (data.newPassword.trim().length < MIN_PWD_LENGTH) throw Error('userSettings.changePassword.tooShort')
    if (data.newPassword !== data.newPasswordConfirmation) throw Error('userSettings.changePassword.notMatching')
  }

  _doRefresh() {
    if (ExpiryCookie.isExpired()) {
      this.logout()
    }

    refreshTimeoutId = undefined
    this.refresh().then((response) => {
      this.fetchCookies(response.access_token).then(() => this.scheduleRefresh())
    })
  }

  fetchCookies(accessToken: string): Promise<void> {
    return defaultApi
      .post<any, AxiosResponse<void, any>>('/api/auth/token/cookie', undefined, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        redirectOn401: false,
      })
      .then((response) => response.data)
  }

  fetchCurrentUser(): Promise<AuthUser> {
    return defaultApi.get<AuthUser>('/api/auth/me').then((response) => response.data)
  }

  login(username: string, password: string, otp: string): Promise<Tokens> {
    const headers = otp !== '' ? {otp} : {}

    return defaultApi
      .post<any, AxiosResponse<Tokens, any>>(
        '/api/auth/token',
        {username, password},
        {
          headers,
          redirectOn401: false,
        }
      )
      .then((response) => response.data)
  }

  logout() {
    window.location.replace('/rateboard/logout')
  }

  refresh(isExcludedFromLoadingBar = true) {
    const customData = {isExcludedFromLoadingBar}

    return defaultApi
      .post<any, AxiosResponse<Tokens, any>>('/api/auth/token/refresh', undefined, {
        redirectOn401: false,
        customData,
      })
      .then((response) => response.data)
  }

  reset(username: string): Promise<void> {
    return defaultApi.post<any, AxiosResponse<void, any>>('/api/auth/password-resets', {username}).then((response) => response.data)
  }

  resetPassword(userId: number, data: ResetPassword): Promise<void> {
    try {
      this.#validateNewPassword(data)
    } catch (error) {
      return Promise.reject(error)
    }

    return defaultApi.post(`/api/auth/users/${userId}/credentials?reset=true`, data)
  }

  changePassword(userId: number, data: ChangePassword): Promise<void> {
    try {
      this.#validateNewPassword(data)
    } catch (error) {
      return Promise.reject(error)
    }

    return defaultApi.put(`/api/auth/users/${userId}/credentials`, data)
  }

  allPasswordsNotEmpty(...passwords: Maybe<string>[]) {
    return passwords.every((p) => isNotNullOrUndefined(p) && p.trim().length > 0)
  }

  scheduleRefresh() {
    if (ExpiryCookie.isExpired()) {
      this.logout()
    }

    if (refreshTimeoutId === undefined) {
      const curTime = Date.now()
      const difference = ExpiryCookie.get() - REFRESH_BEFORE_MS - curTime
      const limitedDifference = Math.max(1000, Math.min(difference, 2147483647))

      refreshTimeoutId = window.setTimeout(this._doRefresh.bind(this), limitedDifference)
    }
  }
}

export default new AuthService()
