import {defineStore} from 'pinia'

type State = {
  requests: Set<string>
  isLegacyLoading: boolean
}

const defaultState: State = {
  requests: new Set<string>(),
  isLegacyLoading: false,
}
export const useLoadingStore = defineStore('loading', {
  state(): State {
    return {...defaultState}
  },
  actions: {
    __addRequestId(id: string) {
      this.requests.add(id)
    },
    __removeRequestId(id: string) {
      this.requests.delete(id)
    },
  },
  getters: {
    isLoading: (state: State) => state.requests.size !== 0 || state.isLegacyLoading,
  },
})
